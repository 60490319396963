import React from "react";

import "./homemob.css";
import "./dashboardmob.css";
import "./noticemob.css";
import { Button } from "react-bootstrap";


const switchStudent = () => {
  console.log("MAGIC SWITCH")
  alert("switch state from child1 to child2 and vice versa")
}

const HomeMobile = (item, model) => {
  return (
 
          <div>
            <p></p>
            <Button onClick = {switchStudent} className = "student_button">{item.name}</Button> 
            <p></p>
            </div>
  );
};

export default HomeMobile;
