import React, { Component } from "react";
//import { Redirect } from "react-router-dom";
import "./paymentmob.css";
//import { render } from "@testing-library/react";
import { Table } from "react-bootstrap";
import { Button } from "reactstrap";
import pdf from "../Documents/example.pdf";

const PaymentMobInfo = (item, model) => {
  return (
    
          
            <tr>
              <td>{item.name}</td>
              <td>{item.date}</td>
              <td>
                <Button className="payment__button">
                  <a href={item.item} target="__blank">
                    {item.rec_No}
                  </a>
                </Button>
              </td>
            </tr>
          

  );
};

export default PaymentMobInfo;
