import React, { useState } from "react";
import "./projectmob.css";
import { Redirect, Link, useHistory } from "react-router-dom";
import { Table } from "react-bootstrap";
import { Button } from "reactstrap";

function ProjectMobInfo (item,model) {

let history = useHistory();


const redirect = () => {

  history.push("/project-title")
  
}

return (

              <tr>
                <td>{item.name}</td>
                <td>{item.date}</td>
                <td>
                  
                    {item.title}
                  
                </td>
              </tr>
    );

  }

export default ProjectMobInfo;


