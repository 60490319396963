import React from "react";
import {
  Card,
  CardImg,
  CardText,
  CardBody,
  CardTitle,
  Button,
  Container,
} from "reactstrap";
import "./certmob.css";
//import pdf from "../Documents/example.pdf";





const CertMobile = (item, Certificates) => {
  return (
    <div className = "header__certmob">
      
    <div
      key={Certificates}
    >
     
      <Container fluid>
        <Card className="cert__card">
          <CardImg />
          <CardBody>
            <CardTitle>Cert For : {item.name}</CardTitle>
            <CardText>Cert Title : {item.text}</CardText>
            <CardText>Date : {item.date}</CardText>
            <Button>
              <a href={item.item} target="__blank">
                Download
              </a>
            </Button>
          </CardBody>
        </Card>
      </Container>
    </div>
    </div>
  );
};

export default CertMobile;

/*

{
             data.map((...items)=> (
              <tr>
                <td>
                  {this.props.reports.repstudents1}
                </td>
                <td>
                  <Button className="report__button">
                  <a href={pdf1} target="__blank">
                    Download
                  </a>
                </Button>
                </td>
              </tr>
            ))
            } 

*/
