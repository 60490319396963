import React, { Container, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import ProtectedRoute from "./components/ProtectedRoute";
import "./App.css";
import DesktopBreakpoint from "./responsive_utils/desktop_breakpoint";
import TabletBreakpoint from "./responsive_utils/tablet_breakpoint";
import PhoneBreakpoint from "./responsive_utils/phone_breakpoint";
import HomeMobile from "./components/HomeMobile";
import ProjectMobile from "./components/ProjectMobInfo";
import ReportMobile from "./components/ReportMobile";
import CertMobile from "./components/CertMobile";
import ProjectTitMob1 from "./components/ProjectTitMob1";
import PaymentMobInfo from "./components/PaymentMobInfo";
import Unauthorized from "./components/Unauthorized";
import HheaderMobileComponent from "./components/HheaderMobileComponent";
//import { AuthContext } from "./context/auth";
import LoginOne from "./components/LoginOne";
import axios from "axios";
import Cookie from "js-cookie"; //store user and pw here ? session 
import {Table,Button} from "react-bootstrap";
import ResponsiveEmbed from 'react-bootstrap/ResponsiveEmbed'

function App() {
 
  const [user, setUser] = useState("");
  const [state, setState] = useState({
    students: {
      parent: [],
      parentnum: [],
      name: null,
      age: null,
      school: null,
    },

    cert : {
      text: null,
      date: null,
    },
    projects: {
      author1: null,
      title1: null,
      type1: null,
      date1: null,
      author2: null,
      title2: null,
      type2: null,
      date2: null,
    },

    loading: true,
    credentials: null,
    responseData: [],
    newcredentials: null,
    testPrint: [],
    stud:[],
    certificate: [],
    report: [],
    payment: [],
    project:[],
    
  });

  const refData = () => {
    async function refetchCreds() {
      var username = localStorage.getItem("username");
      var password = localStorage.getItem("password"); //store elsewhere => not safe!
      const user = { username, password };

      const newResponse = await axios.post(
        "https://realfunapp.herokuapp.com/api/token/",
        user
      );
      setUser(newResponse.data);

      console.log("Auto relogin", newResponse.data);
      //alert("Session Expired. Press OK to stay logged in")
      console.log("new token", newResponse.data.access);
      localStorage.setItem("user", newResponse.data.access);
      Cookie.set("ref.user", newResponse.data.refresh);
      state.newcredentials = newResponse.data.access; //reassigned state.credentials to new access token for reGET
      state.credentials = state.newcredentials; //swap creds with new creds for reGET

      //Auto Relogin creds => fulfilled!
    }

    if ((state.credentials = localStorage.getItem("user"))) {
      if ((state.responseData = sessionStorage.getItem(["responseData"]))) {
        const headers = {
          Authorization: `Bearer ${state.credentials}`,
        };
        axios
          .get("https://realfunapp.herokuapp.com/realfunapp/student/", {
            headers,
          })
          .then((res) => {
            //setresponseData(res.data);
            //console.log(JSON.parse(res.data), "response");
            sessionStorage.setItem("responseData", res.data);
          })
          .catch((error) => {
            console.error(error, "cant use refresh token to fetch");

            //alert("Keep logged me in!");
            //auto relogin here => passing same username and password
            //refresh page!
            refetchCreds();
          });

        
        //console.log(state.responseData, "Before parse JSON")
        var items = JSON.parse([state.responseData]);
        state.testPrint = items; //items (model data)stored in state
   

        var setCerts = [];
        var setReports = [];
        var setPayments = [];
        var setStudents = []
        var setProjects = []


        for ( let i = 0 ; i < items.length; i++){
          if(items[i].model == "realfunapp.certificate"){
          var setCert = {};
          
          setCert.student = items[i].fields.student;
          setCert.name = items[i].fields.name;
          setCert.text = items[i].fields.text;
          setCert.date = items[i].fields.date;
          setCert.item = items[i].fields.item;
          setCerts.push(setCert)

          //console.log(setCert, "cert test")
          //console.log(setCerts, "certs test")
         
          }
          
          if(items[i].model == "realfunapp.report"){

          var setReport = {};
          
          setReport.student = items[i].fields.student;
          setReport.name = items[i].fields.name;
          setReport.progress = items[i].fields.progress;
          setReport.title = items[i].fields.title;
          setReport.item = items[i].fields.item;
          setReport.comment = items[i].fields.comment;
          setReport.date = items[i].fields.date;
          setReports.push(setReport)

          //console.log(setReport, "rep")
          //console.log(setReports,"reps")
  
          } 

          if(items[i].model == "realfunapp.project"){

            var setProject = {}
            setProject.author = items[i].fields.author;
            setProject.name = items[i].fields.name;
            setProject.title = items[i].fields.title;
            setProject.project_type = items[i].fields.project_type;
            setProject.project_comment = items[i].fields.project_comment;
            setProject.date = items[i].fields.date;

            setProjects.push(setProject)
            
            } 

          if(items[i].model == "realfunapp.receipt"){

          var setPayment = {};
          setPayment.student = items[i].fields.student;
          setPayment.name = items[i].fields.name;
          setPayment.rec_No = items[i].fields.rec_No;
          setPayment.item = items[i].fields.item;
          setPayment.amount = items[i].fields.amount;
          setPayment.date = items[i].fields.date;
          setPayments.push(setPayment)
          
          } 

          if(items[i].model == "realfunapp.student"){

          var setStudent = {}; 
          setStudent.parent = items[i].fields.parent[0];
          setStudent.parentnum = items[i].fields.parent[1];
          setStudent.name = items[i].fields.name;
          setStudent.age = items[i].fields.age;
          setStudent.school = items[i].fields.school;
          setStudents.push(setStudent)
          
          } 
      
          
        }

        
       state.stud = Object.values(setStudents); 
       state.certificate = Object.values(setCerts);
       state.report = Object.values(setReports);
       state.payment = Object.values(setPayments);
       state.project = Object.values(setProjects);
       //console.log(state.stud, "state stud")
       //console.log(state.project, "state project")
       //console.log(state.report, "state report")
       //console.log(state.payment, "state pamynet")
       //console.log(setCert, "new cert STATE")  //only get last array why? => need to get all array of cert!
        
    
        //console.log(items, "responseData from GET@login");
        //console.log("usertoken", state.credentials);
        //console.log("ref.token", Cookie.get("ref.user"));
       

       

        //Home
        //TBC project and student state => change to mapping once figured out how to fix the 21/10/2020 bug

        state.students.parent = items[0].fields.parent[0];
        state.students.parentnum = items[0].fields.parent[1];
        state.students.name = items[0].fields.name;
        state.students.age = items[0].fields.age;
        state.students.school = items[0].fields.school;
        

        

        setState({
          //redirect: true,
          loading: false,
          credentials: state.credentials,
          responseData: state.responseData,
          parent: state.students.parent,
          parentnum: state.students.parentnum,
          name: state.students.name,
          age: state.students.age,
          school: state.students.school,
          //set new state @ FOR LOOP
          testPrint: state.testPrint, //use this @ return map => good. BUG: renders all components and redundant ones
          //need to change variable names (every variables are unique) ? or how ? no idea!
          stud:state.stud,
          report:state.report,
          certificate:state.certificate,
          payment:state.payment,
          project:state.project,
        });

        //else if !credentials -> redirect to /
        //catch error and redirect to / if !creds !responsedata
        
      }
    }
  };

  useEffect(() => {
  
    refData();
    const interval = setInterval(() => {
      refData();
    }, 20000); //20 seconds
    return () => clearInterval(interval);
  }, []);

  return ( //switch child state (pK) and renders the choosen pK data
    
    <div className = "parent__div">
      <DesktopBreakpoint>
        <div className = "center__item">
        <h1>Mobile View Only</h1>
        </div>
      </DesktopBreakpoint>
      <TabletBreakpoint>
        <div className = "center__item">
        <h1>Mobile View Only</h1>
        </div>
      </TabletBreakpoint>
      <PhoneBreakpoint>
        <Switch>
          <Route exact path="/">
            <Redirect to="/login" />
          </Route>
          <Route exact path="/login" component={LoginOne}>
            <LoginOne />
          </Route>
          {/*ProtectedRoute @afterlogin */}
          <ProtectedRoute exact path="/home" component={HomeMobile}>
            <HheaderMobileComponent />
            <div className = "header__namedis">
                <h6>Welcome, {state.parent}</h6>
                <div className="content__home">                
                  <div className="notice__box">
                    <div className="notice__head">
                      <h2>How to use this portal</h2> 
                      {
                        /**
                         * Embed video @ src : HowTo navigate this parent portal (TBC)
                         *  Responsive ?
                        */
                      }
                       <iframe  src="https://www.youtube.com/embed/xbR79LUfl54" frameborder="0" allow=" autoplay" allowfullscreen></iframe>
                      
                    </div>
                  </div>
                  <p></p>
                  <div className="dashboard__box">
                    <div className="dashboard__head">
                      <h2>Dashboard</h2>
                      <p></p>
                      <h6>
                        {/**
                         * Content Here TBC
                         */}
                      </h6>
                    </div>
                  </div>
                  </div> 
                  </div>
          </ProtectedRoute>
          <ProtectedRoute
            exact
            path="/student-project"
            component={ProjectMobile}
          >
            <HheaderMobileComponent />
            <div className = "project__title">
              
              Project 
              <p></p>
              <h6>List that has been done by student(s)</h6>
            <div className="project__table">
          <Table striped bordered responsive hover className="project__table">
            <thead>
              <tr>
                <th>Author</th>
                <th>Project Date</th>
                <th>Project Title</th>
              </tr>
            </thead>
            <tbody>
            {state.project.map((item, model) => (
                          <ProjectMobile
                            key={model}
                            author = {item.author}
                            name = {item.name}
                            title = {item.title}
                            project_type = {item.project_type}
                            date =  {item.date}
                            project_comment = {item.project_comment}
                            />
                        ))}
            </tbody>
          </Table>
        </div>   
        <h6>Tap <a href = "/project-title">here</a> to view student(s) project in more detail! </h6>   
        </div>      
          </ProtectedRoute>
          <ProtectedRoute
            exact
            path="/project-title"
            component={ProjectTitMob1}
          >
            <HheaderMobileComponent />
            {state.project.map((item, model) => (
                          <ProjectTitMob1
                            key={model}
                            author = {item.author}
                            name = {item.name}
                            title = {item.title}
                            project_type = {item.project_type}
                            project_comment = {item.project_comment}
                            date =  {item.date}
                            />
                        ))}
          </ProtectedRoute>
          <ProtectedRoute exact path="/report" component={ReportMobile}>
            <HheaderMobileComponent />
            <div className = "report__header">
              <h2>Report</h2>
              </div>
            <Table striped bordered hover responsive className="report__table">
            <thead>
              
                <th>Student</th>
                <th>Date</th>
                <th>Progress </th>
                <th>Report </th>
                <th>Comment </th>
              
           </thead>
           <tbody> 
            {state.report.map((item, model) => (
              <ReportMobile
                key={model}
                student = {item.student}
                name = {item.name}
                progress = {item.progress}
                date={item.date}
                title = {item.title}
                item= {item.item}
                comment = {item.comment}
                />
            ))}
            </tbody>
            
            </Table>
          </ProtectedRoute>
          <ProtectedRoute exact path="/certificates" component={CertMobile}>
            <HheaderMobileComponent />
            <div className = "cert__header">
              <h2>Certificate</h2>
              </div>
            {state.certificate.map((item,model) => (
              <CertMobile
                key={model}
                student= {item.student}
                name = {item.name}
                text={item.text}
                item = {item.item}
                date={item.date}
              />
            ))}
          </ProtectedRoute>
          <ProtectedRoute exact path="/fee-payment" component={PaymentMobInfo}>
            <HheaderMobileComponent />
            <div className = "payment__header">
              <h2>Payment</h2>
              </div>
              <Table
              striped
              bordered
              hover
              size="sm"
              className="payment_table"
              responsive="sm">
             <thead>
              <tr>
                <th>Student</th>
                <th>Date</th>
                <th>Receipt #</th>
              </tr>
          </thead>
          <tbody>
            {state.payment.map((item, model) => (
              <PaymentMobInfo
                key={model}
                student={item.student}
                name={item.name}
                rec_No={item.rec_No}
                item={item.item}
                amount={item.amount}
                date={item.date}
              />
            ))}
            </tbody>
            </Table>
          </ProtectedRoute>
          <Route path="/*" component={Unauthorized}></Route>
        </Switch>
      </PhoneBreakpoint>
    </div>
  );
}

export default App;


/*
          

*/

