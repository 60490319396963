import React from "react";

import { Navbar, Nav } from "react-bootstrap";
import "./hheadermob.css";

class BootstrapNavbar extends React.Component {
  logout() {
    console.log("logout");
    window.localStorage.clear();
    window.sessionStorage.clear();  //states ==> null
    window.location.replace("/login"); //clear cache and reload page from HOME to login
  }
  render() {
    return (
      <div>
        <div className="row">
          <div className="col-md-12">
            <Navbar bg="primary" variant="dark" expand="lg" >
              <Navbar.Brand href="/home">
                <img
                  src="assets/realfunfavicon.png"
                  height="40px"
                  width="30px"
                  alt="Realfun favicon"
                />
                <span className="head__name">
                  <strong>Parent Portal</strong>
                </span>
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav" className="navbar__toggle">
                <Nav.Link href="/home">
                  <span><strong>Home </strong></span> <span className="fas fa-home fa-lg"></span>
                </Nav.Link>
                <Nav.Link href="/student-project">
                <span><strong>Student's Work </strong></span><span className="fas fa-book fa-lg"></span>
                </Nav.Link>
                <Nav.Link href="/report">
                  {" "}
                  <span><strong>Report </strong></span><span className="fas fa-chart-bar fa-lg"></span>
                </Nav.Link>
                <Nav.Link href="/certificates">
                <span><strong>Certificate </strong></span><span className="fas fa-certificate fa-lg"></span>
                </Nav.Link>
                <Nav.Link href="/fee-payment">
                <span><strong>Payment Fee </strong></span><span className="fas fa-receipt"></span>
                </Nav.Link>
                <Nav.Link onClick={this.logout} href="/login">
                <span><strong>Logout </strong></span><span className="fas fa-sign-out-alt"></span>
                </Nav.Link>
              </Navbar.Collapse>
            </Navbar>
            <br />
          </div>
        </div>
      </div>
    );
  }
}

export default BootstrapNavbar;
