import React, {useState} from "react";
import "./reportmob.css";
import { Alert,Button } from "react-bootstrap";




const ReportMobile = (item, model) => {

  
  const [show, setShow] = useState(false);


   const commentFunc = () => {
    alert(item.comment);

  }


 
  return (   
    <>
          <tr>
            <td>{item.name}</td>
            <td>{item.date}</td>
            <td>{item.progress}</td>
            <td>
              <button className="report__button">
                <a href={item.item} target="__blank">
                  {item.title}
                </a> 
              </button>
            </td>
          <td>
            <div>
            <Alert show={show} variant="primary">
              <p>
                {item.comment}
              </p>
              <hr />
              <div className="d-flex justify-content-end">
                <Button onClick={() => setShow(false)} variant="outline-primary">
                  Close 
                </Button>
              </div>
            </Alert>
      {!show && <button className="report__button" onClick={() => setShow(true)}>Show comment</button>}
    
            </div>
          </td>
          </tr>
          
          
      </>  
  );
};

export default ReportMobile;


