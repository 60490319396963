import React, { Component } from "react";
import { Container, Button } from "reactstrap";
import "./projecttitmob.css";
import { useHistory } from "react-router-dom";


function ProjectTitMob1 (item,model) {

 let history = useHistory();
 const redirect = () => {
   history.push("/student-project")
 }

  
    return (
      <div className = "project_card">
      <div className="project__tit">
      <p>
        <h4>
          Author: {item.name}
        </h4>
      </p>
        <h4>
          {item.title}
        </h4>
      
        <Container className="project__vid">
          <div>
            <iframe
              src={item.project_type}
              frameBorder="1"
              allow="autoplay; encrypted-media"
              allowFullScreen
              height="250px"
              title="video"
            />
          </div>
        </Container>
        <h5>Date:{item.date}</h5>
        <Container>
          <div className="project__comment">
            <strong>Techer's Comment: </strong>
              {item.project_comment}
          </div>
          <p></p>
          
        </Container>
      </div>
      </div>
    );
}

export default ProjectTitMob1;
