import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookie from "js-cookie";
import "./login.css";
import { Redirect } from "react-router-dom";
import { render } from "@testing-library/react";

const LoginOne = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [user, setUser] = useState();
  const [responseData, setresponseData] = useState([]);
  const [state, setState] = useState({
    loading:false,
  })
  // login the user

 

  async function fetchData() {

    setState({ loading: true });

    setTimeout(() => {
      setState({ loading: false });
    }, 2000);

    const user = { username, password };
    // send the username and password to the server
    const response = await axios.post(
      "https://realfunapp.herokuapp.com/api/token/",
      user
    ).
    then((response) => {
    //store user and password => temp @ localstorage
    //alert("logging in")
    localStorage.setItem("username", user.username);
    localStorage.setItem("password", user.password);

    setUser(response.data);

    console.log("access.token", response.data.access);
    console.log("refresh.token", response.data.refresh);
    localStorage.setItem("user", response.data.access);
    
    Cookie.set("ref.user", response.data.refresh);

    if (response.data.access || response.data.refresh) {
      //how to refetch this (with interval? | realtime) ? with valid token

      const headers = {
        Authorization: `Bearer ${response.data.access}`,
      };
      axios
        .get("https://realfunapp.herokuapp.com/realfunapp/student/", {
          headers,
        })
        .then((res) => {
          setresponseData(res.data);
          //console.log(JSON.parse(res.data), "response");
          sessionStorage.setItem("responseData", res.data);
          window.location.replace("/home");
        })
        .catch((error) => {
          console.error(error);
        });
    }

    })
    .catch((error)=> {
      console.log(error);
      alert("Wrong/Empty Creds! Try Again")
    })    

    
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    //alert("Logging in");
    fetchData();
    
  };

  const {loading} = state;

  return (
    
    <div className="container">
      
      <div className="login__image">
        <img
          src={process.env.PUBLIC_URL + "assets/realfunlong.png"}
          width="250"
          height = "100"
          alt="website logo"
        />
      </div>
      <h4>Parent Portal Login</h4>
      <React.Fragment>
        <div className="login__user">
          <form onSubmit={handleSubmit}>
            <label htmlFor="username">Username:</label>
            <input
              type="text"
              value={username}
              placeholder="enter a username"
              onChange={({ target }) => setUsername(target.value)}
            />
            <div className="login__pass">
              <label htmlFor="password">Password :</label>
              <input
                type="password"
                value={password}
                placeholder="enter a password"
                onChange={({ target }) => setPassword(target.value)}
              />
            </div>
            <button className="login__button" onSubmit={handleSubmit} type="submit" disabled={loading}>
            {loading && (
            <i
              className="fa fa-refresh fa-spin"
              style={{ marginRight: "5px" }}
            />
          )}
          {loading && <span>Loading...</span>}
          {!loading && <span>Login</span>}
            </button>
          </form>
        </div>
      </React.Fragment>
    </div>
  );
};

export default LoginOne;
