import React from "react";
import Breakpoint from "./breakpoint";
import PropTypes from "prop-types";

const { object } = PropTypes;

export default function DesktopBreakpoint(props) {
  return <Breakpoint name="desktop">{props.children}</Breakpoint>;
}

DesktopBreakpoint.propTypes = {
  children: object,
};
